export const flutter = (
  <svg viewBox="0 0 128 128">
    <g id="Capa_4">
      <g>
        <g>
          <path fill="#3FB6D3" d="M 12.3,64.2 76.3,0 115.7,0 32.1,83.6 z"></path>
          <path fill="#3FB6D3" d="M 76.3,128 115.7,128 81.6,93.9 115.7,59.1 76.3,59.1 42.2,93.5 z"></path>
        </g>
        <path fill="#27AACD" d="M 81.6,93.9 61.6,73.9 42.2,93.5 61.6,113.1 z"></path>
        <path fill="#19599A" d="M 115.7,128 81.6,93.9 61.6,113.1 76.3,128 z"></path>
        <linearGradient id="SVGID_1_FLUTTER" gradientUnits="userSpaceOnUse" x1="59.3649" y1="116.3598" x2="86.8249" y2="99.3992">
          <stop offset="0" style={{ stopColor: "#1B4E94" }}></stop>
          <stop offset="0.6305" style={{ stopColor: "#1A5497" }}></stop>
          <stop offset="1" style={{ stopColor: "#195A9B" }}></stop>
        </linearGradient>
        <path fill="url(#SVGID_1_FLUTTER)" d="M 61.6,113.1 92.4,104.7 81.6,93.9 z"></path>
      </g>
    </g>
  </svg>
);
